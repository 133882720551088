import { ComponentStyleConfig } from "@chakra-ui/react";

export const CardStyles: ComponentStyleConfig = {
  baseStyle: {
    bg: "transparent",
    borderWidth: 1,
    borderColor: "surfaceBorders",
    borderRadius: "sm",
    p: 4,
  },
};
