import { Box, Button, ButtonGroup, Icon, useColorMode } from "@chakra-ui/react";
import { FiMoon, FiSun } from "react-icons/fi";

export function ButtonMode() {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <Box w="full" p={2}>
      <ButtonGroup w="full" isAttached variant="outline">
        <Button
          leftIcon={<Icon as={FiSun} />}
          w="full"
          isDisabled={colorMode === "light"}
          onClick={toggleColorMode}
        >
          Light
        </Button>
        <Button
          rightIcon={<Icon as={FiMoon} />}
          w="full"
          isDisabled={colorMode === "dark"}
          onClick={toggleColorMode}
        >
          Dark
        </Button>
      </ButtonGroup>
    </Box>
  );
}
