import { ComponentStyleConfig } from "@chakra-ui/react";

export const ProjectTicketAttachmentStyles: ComponentStyleConfig = {
  baseStyle: (props) => ({
    projectTicketAttachmentContainer: {
      justifyContent: "space-between",
      w: "full",
    },
    projectTicketAttachmentText: {
      fontSize: "xs",
      maxW: "220px",
    },
  }),
};
