import { Box, useStyleConfig } from "@chakra-ui/react";
import { forwardRef } from "react";
import { CardProps } from ".";

export const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ children, ...rest }, ref) => {
    const styles = useStyleConfig("Card");
    return (
      <Box __css={styles} ref={ref} {...rest}>
        {children}
      </Box>
    );
  }
);
