import { ComponentStyleConfig } from "@chakra-ui/react";

export const ProjectProgressStyles: ComponentStyleConfig = {
  parts: ["progress", "text"],
  baseStyle: {
    progress: {
      w: "full",
      rounded: "md",
      mt: 2,
    },
    text: {
      mt: 1,
      fontSize: "xs",
    },
  },
};
