import {
  HStack,
  VStack,
  Divider,
  Stack,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import {
  FiHome,
  FiColumns,
  FiUsers,
  FiSun,
  FiSettings,
  FiTrello,
  FiGrid,
  FiPlusSquare,
} from "react-icons/fi";

import { Logo } from "../Logo";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import React from "react";
import { SidebarButtonItem } from "./SidebarButtonItem";
import { SidebarUser } from "./SidebarUser";
import { ButtonMode } from "../ButtonMode";
import { useSession } from "src/hooks/useSession";

const Notifications = dynamic(() => import("../Notifications"), { ssr: false });

function SidebarInner() {
  const { isOrganisation } = useSession();
  const styles = useMultiStyleConfig("Sidebar");
  const router = useRouter();

  const isActive = (path: string, equal = false) => {
    if (equal) {
      return router.pathname === path;
    }
    return router.pathname.includes(path);
  };

  return (
    <VStack as="aside" sx={styles.sidebar}>
      <HStack sx={styles.logoContainer}>
        <Logo />
      </HStack>
      <Stack sx={styles.buttonItemsContainer}>
        <SidebarButtonItem
          isActive={isActive("/", true)}
          label="Dashboard"
          icon={FiHome}
          to="/"
        />
        {!isOrganisation && (
          <SidebarButtonItem
            isActive={isActive("feed")}
            label="Feed"
            icon={FiGrid}
            to="/feed"
          />
        )}
        <SidebarButtonItem
          isActive={isActive("project")}
          label="Projects"
          icon={FiTrello}
          to="/projects"
        />
        {isOrganisation && (
          <SidebarButtonItem
            isActive={isActive("team-members")}
            label="Team members"
            icon={FiUsers}
            to="/team-members"
          />
        )}
        {!isOrganisation && (
          <SidebarButtonItem
            isActive={isActive("applications")}
            label="Applications"
            icon={FiPlusSquare}
            to="/applications"
          />
        )}
        <SidebarButtonItem
          isActive={isActive("settings")}
          label="Settings"
          icon={FiSettings}
          to="/settings"
        />
        <Notifications />
      </Stack>
      <Stack sx={styles.userContainer}>
        <ButtonMode />
        <Divider sx={styles.divider} />
        <SidebarUser />
      </Stack>
    </VStack>
  );
}

export const Sidebar = React.memo(SidebarInner);
