import { ComponentStyleConfig } from "@chakra-ui/react";

export const ContextualSidebarStyles: ComponentStyleConfig = {
  baseStyle: (props) => ({
    width: props?.width || "280px",
    h: "full",
    borderLeftWidth: 1,
    borderLeftColor: "surfaceBorders",
    overflow: "scroll",
  }),
};
