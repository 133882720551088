import { ComponentStyleConfig } from "@chakra-ui/react";

export const PageTitleStyles: ComponentStyleConfig = {
  parts: [
    "pageTitleTitle",
    "pageTitleCenter",
    "pageTitleRight",
    "pageTitleContainer",
  ],
  baseStyle: {
    pageTitleContainer: {
      borderBottomWidth: 1,
      borderBottomColor: "surfaceBorders",
      p: 4,
      px: 8,
      gridTemplateColumns: "repeat(3, 1fr)",
      alignItems: "center",
    },
    pageTitleTitle: {
      fontSize: "xl",
      fontFamily: "'Quicksand', sans-serif",
    },
    pageTitleRight: {
      textAlign: "right",
    },
    pageTitleCenter: {
      textAlign: "center",
    },
  },
};
