import { Box, Flex, IconButton } from "@chakra-ui/react";
import { LayoutProps } from ".";
import { Sidebar } from "./Sidebar";
import { useState } from "react";
import { HamburgerIcon } from "@chakra-ui/icons";

export default function Layout({ children }: LayoutProps) {
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <Box as="main" minW="full" overflow={"hidden"}>
      <Flex
        minW={["calc(100vw + 280px)", "calc(100vw + 280px)", "initial"]}
        overflow="scroll"
        h="100vh"
        flexWrap={["nowrap", "nowrap", "initial"]}
        transform={[
          `translateX(${isMenuOpen ? 0 : -280}px)`,
          `translateX(${isMenuOpen ? 0 : -280}px)`,
          "initial",
        ]}
        transition="all .25s ease-in-out"
        width="full"
      >
        <Sidebar />
        <Box
          minW={["100vw", "100vw", "initial"]}
          overflowY="auto"
          flexGrow={[2, 2, "100%"]}
        >
          <Box display={["block", "block", "none"]} mb={4}>
            <IconButton
              variant={"ghost"}
              aria-label="Open menu"
              onClick={() => setMenuOpen(!isMenuOpen)}
            >
              <HamburgerIcon fontSize={"2xl"} />
            </IconButton>
          </Box>
          <Box onClick={() => setMenuOpen(false)}>{children}</Box>
        </Box>
      </Flex>
    </Box>
  );
}
