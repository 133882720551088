import { ComponentStyleConfig } from "@chakra-ui/react";

export const ProjectTicketStyles: ComponentStyleConfig = {
  baseStyle: (props) => ({
    projectTicketCard: {
      rounded: "md",
      bg: "background",
    },
    projectTicketId: {
      fontSize: "sm",
      opacity: 0.5,
    },
    projectTicketTitle: {
      my: 1,
    },
    projectTicketContainer: {
      justify: "space-between",
      mt: 3,
    },
    projectTicketAttributeContainer: {
      borderWidth: 1,
      borderColor: "surfaceBorders",
      px: 3,
      py: 1,
      rounded: "md",
      alignItems: "center",
    },
    projectTicketAttributeIcon: {
      color: "surfaceText",
      fontSize: "md",
    },
    projectTicketAttributeData: {
      fontSize: "sm",
    },
  }),
};
