import {
  ApolloClient,
  NormalizedCacheObject,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";
import { WebSocketLink } from "apollo-link-ws";
import { HttpLink } from "apollo-link-http";
import { split } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
import { isEqual, merge } from "lodash";
import getConfig from "next/config";
import { useMemo } from "react";
import { onError } from "@apollo/client/link/error";

const { publicRuntimeConfig } = getConfig();

const isSSR = typeof window === "undefined";
export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

export const getHttpLink = (token: string) => {
  return new HttpLink({
    uri: `${publicRuntimeConfig.NEXT_PUBLIC_GLOUALLY_ENGINE_BASE_URL}v1/graphql`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getWsLink = (token: string) => {
  const wsLink = new WebSocketLink({
    uri: `${publicRuntimeConfig.NEXT_PUBLIC_GLOUALLY_ENGINE_BASE_WS}v1/graphql`,
    options: {
      reconnect: true,
      connectionParams: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  });

  return wsLink;
};

const getLink = (token: string) => {
  return split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    getWsLink(token),
    getHttpLink(token)
  );
};

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError)
    console.log(`[Network error]: ${JSON.stringify(networkError)}`);
});

export const createApolloClient = (token: string) => {
  return new ApolloClient({
    ssrMode: isSSR,
    link: errorLink.concat(
      (process.browser
        ? getLink(token)
        : getHttpLink(token)) as unknown as ApolloLink
    ),
    cache: new InMemoryCache(),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export function initializeApollo(
  initialState?: NormalizedCacheObject,
  token?: string
) {
  const _apolloClient = apolloClient ?? createApolloClient(String(token));

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray: any, sourceArray: any) => [
        ...sourceArray,
        ...destinationArray.filter((d: any) =>
          sourceArray.every((s: any) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: any
) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps: any, token: string) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state, token), [state]);
  return store;
}
