import { Box, Center, HStack } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { ButtonMode } from "../ButtonMode";
import { Card } from "../Card";
import { Logo } from "../Logo";

export function AuthLayout({ children }: PropsWithChildren<{}>) {
  return (
    <Center minH="100vh">
      <Box py={8}>
        <HStack justify="center" py={8}>
          <Logo />
        </HStack>
        <Box position="absolute" right={4} top={4}>
          <ButtonMode />
        </Box>
        <Card minWidth={480} p={8} rounded="md">
          {children}
        </Card>
      </Box>
    </Center>
  );
}
