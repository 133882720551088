import { ComponentStyleConfig } from "@chakra-ui/react";

export const FilterCardStyles: ComponentStyleConfig = {
  parts: ["title", "container", "checkbox", "label", "labelText"],
  baseStyle: {
    title: {
      fontSize: "md",
      mb: 4,
      display: "flex",
      justifyContent: "space-between",
    },
    container: {
      mb: 4,
    },
    checkbox: {
      mr: 2,
    },
    labelText: {
      fontSize: "sm",
    },
    label: {
      display: "flex",
      cursor: "pointer",
    },
    wrapper: {
      maxH: "320px",
      overflowY: "auto",
    },
  },
};
