import {
  Button,
  HStack,
  Icon,
  Text,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import Link from "next/link";

export function SidebarButtonItem({
  label,
  icon,
  isActive = false,
  to = "",
}: any) {
  const styles = useMultiStyleConfig("Sidebar", { isActive });
  return (
    <Link href={to}>
      <Button
        variant="ghost"
        isActive={isActive}
        sx={styles.buttonItem}
        aria-label={label}
        children={
          <HStack sx={styles.iconContainer}>
            <Icon sx={styles.icon} as={icon} />
            <Text sx={styles.text}>{label}</Text>
          </HStack>
        }
      />
    </Link>
  );
}
