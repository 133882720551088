import { ComponentStyleConfig } from "@chakra-ui/react";

export const CommentFormStyles: ComponentStyleConfig = {
  parts: ["commentFormContainer", "commentFormAvatar", "commentFormTextarea"],
  baseStyle: (props) => ({
    commentFormContainer: {
      py: 4,
      alignItems: "flex-start",
    },
    commentFormAvatar: {
      mr: 2,
    },
    commentFormTextarea: {
      border: 0,
      bg: "surface",
      p: 6,
    },
  }),
};
