import { ComponentStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

export const ProjectApplicationModalStyles: ComponentStyleConfig = {
  parts: [
    "projectApplicationModal",
    "projectApplicationNameContainer",
    "projectApplicationName",
    "projectApplicationLink",
    "projectApplicationModalStatCard",
    "projectApplicationModalBlockTitle",
    "projectApplicationModalFooter",
    "projectApplicationModalFooterButton",
    "projectApplicationModalContent",
  ],
  baseStyle: (props) => ({
    projectApplicationModal: {
      py: 4,
    },
    projectApplicationNameContainer: {
      gap: 0,
    },
    projectApplicationName: {
      fontWeight: 500,
    },
    projectApplicationLink: {
      color: mode("blackAlpha.700", "whiteAlpha.700")(props),
      fontSize: "xs",
      fontWeight: 500,
    },
    projectApplicationModalStatCard: {
      py: 2,
      rounded: "md",
      w: "full",
      fontSize: "sm",
      minW: "min-content",
      flex: 1,
    },
    projectApplicationModalStatNumber: {
      fontSize: "sm",
    },
    projectApplicationModalStatLabel: {
      fontSize: "sm",
      color: mode("blackAlpha.700", "whiteAlpha.700")(props),
    },
    projectApplicationModalBlockTitle: {
      fontSize: "xs",
      fontWeight: 500,
      color: mode("blackAlpha.700", "whiteAlpha.600")(props),
      mb: 2,
    },
    projectApplicationModalFooter: {
      justifyContent: "space-between",
    },
    projectApplicationModalFooterButton: {
      mr: 2,
    },
    projectApplicationModalContent: {
      mt: 4,
    },
  }),
};
