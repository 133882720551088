import React, { PropsWithChildren } from "react";
import { Users } from "src/generated/graphql";

export type Session = { user?: Users } & Record<string, any>;

export const SessionContext = React.createContext<{
  session: Session;
  isAdmin: boolean;
  isOrganisation: boolean;
  isOwner: (userId: string) => boolean;
  isFinance: boolean;
  isTeacher: boolean;
}>({
  session: { user: undefined },
  isAdmin: false,
  isOrganisation: false,
  isOwner: () => false,
  isFinance: false,
  isTeacher: false,
});

export function SessionProvider({
  children,
  session,
}: PropsWithChildren<{
  session: Session;
}>) {
  const isOrganisation: boolean =
    session?.user?.belongsTo || session?.user?.isOrganisationAdmin;
  const isOwner = (userId: string) => Boolean(session?.user?.id === userId);
  const isAdmin = session?.user?.permissions === "ADMIN";
  const isFinance = session?.user?.permissions === "FINANCE";
  const isTeacher = session?.user?.permissions === "TEACHER";

  return (
    <SessionContext.Provider
      value={{
        session,
        isAdmin,
        isOrganisation,
        isOwner,
        isFinance,
        isTeacher,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}
