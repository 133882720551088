import { ComponentStyleConfig } from "@chakra-ui/react";

export const EntryStyles: ComponentStyleConfig = {
  parts: ["container", "avatar"],
  baseStyle: ({ colorMode }) => ({
    container: {
      w: "full",
      overflow: "hidden",
      px: 8,
      py: 4,
      pb: 8,
      borderColor: "transparent",
      borderBottomColor: "surfaceBorders",
    },
    avatar: {
      mr: 2,
    },
    authorName: {
      fontSize: "sm",
    },
    organisationName: {
      color: colorMode === "dark" ? "inherit" : "gray.600",
      fontSize: "xs",
    },
    header: {
      mb: 8,
      alignItems: "center",
    },
    attributeContainer: {
      mr: 10,
      fontSize: "sm",
    },
    attributeLabel: {
      fontWeight: 700,
      fontSize: "xs",
      textTransform: "uppercase",
    },
    buttonContainer: {
      p: 2,
    },
    buttonIcon: {
      mr: 2,
    },
  }),
};
