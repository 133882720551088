import { gql, useLazyQuery } from "@apollo/react-hooks";
import { useSession } from "../hooks/useSession";
import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useRef,
} from "react";
import { Flex, Spinner } from "@chakra-ui/react";

export const ProfileContext = createContext(null);

export const GET_USER = gql`
  query getUserProvider($userId: uuid) {
    users(where: { id: { _eq: $userId } }) {
      id
      name
      image
      email
      areasOfExpertise {
        areaOfExpertise {
          id
          label
        }
      }
      location
      permissions
      organisation {
        id
        name
        image
      }
      belongOrganisation {
        id
        name
        image
      }
      slug
    }
  }
`;

export const ProfileProvider = React.memo(({ children }: PropsWithChildren) => {
  const isCalled = useRef(false);
  const { session } = useSession();
  const [getProfile, { data, loading }] = useLazyQuery(GET_USER, {
    variables: {
      userId: session?.user?.id,
    },
  });

  useEffect(() => {
    if (!isCalled.current) {
      getProfile();
      isCalled.current = true;
    }
  }, [session?.user?.id]);

  if (loading && !isCalled.current) {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        h="h-screen"
        w="w-screen"
      >
        <Spinner size="xl" color="teal" />
      </Flex>
    );
  }

  return (
    <ProfileContext.Provider value={data?.users[0]}>
      {children}
    </ProfileContext.Provider>
  );
});
