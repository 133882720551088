import { ChakraProvider, ColorModeScript, GlobalStyle } from "@chakra-ui/react";
import TimeAgo from "javascript-time-ago";
import { getSession } from "next-auth/react";
import App, { AppContext } from "next/app";
import { AuthLayout } from "../components/AuthLayout";
import Layout from "../components/Layout";
import { ProfileProvider } from "../providers/ProfileProvider";
import { SessionProvider } from "../providers/SessionProvider";
import { theme } from "../theme";
import { ApolloProvider } from "@apollo/client";
import en from "javascript-time-ago/locale/en.json";
import { Fonts } from "src/components/Fonts";
import { useApollo } from "src/providers/SecureApolloProvider";
import Script from "next/script";

TimeAgo.addDefaultLocale(en);

function GlouallyApp({ Component, pageProps, session }: any) {
  const client = useApollo(pageProps, session?.token);
  const Wrapper =
    Component.layout === "AuthLayout"
      ? AuthLayout
      : ({ children }: any) => (
          <Layout>
            <ApolloProvider client={client}>
              <ProfileProvider>{children}</ProfileProvider>
            </ApolloProvider>
          </Layout>
        );
  return (
    <>
      <SessionProvider session={session}>
        <ChakraProvider theme={theme}>
          <ColorModeScript initialColorMode={theme.config.initialColorMode} />
          <GlobalStyle />
          <Fonts />

          <Wrapper>
            <Component {...pageProps} />
          </Wrapper>
        </ChakraProvider>
      </SessionProvider>
      {process.env.NODE_ENV === "production" && (
        <>
          <Script
            src="https://www.googletagmanager.com/gtag/js?id=GTM-MN9NS8C"
            strategy="afterInteractive"
          />
          <Script id="google-analytics" strategy="afterInteractive">
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'GTM-MN9NS8C');
        `}
          </Script>
        </>
      )}
    </>
  );
}

GlouallyApp.getInitialProps = async (appContext: AppContext) => {
  const session: any = await getSession(appContext.ctx);
  const appProps: any = await App.getInitialProps(appContext);

  if (
    !session &&
    !session?.user &&
    !["/login", "/signup", "/reset-password", "/new-password", "/confirm"].some(
      (url) => appContext?.ctx?.req?.url?.includes(url)
    )
  ) {
    appContext?.ctx?.res?.writeHead(302, { Location: "/login" });
    appContext?.ctx?.res?.end();
    return { ...appProps };
  }

  if (
    session &&
    !session?.user?.emailVerified &&
    appContext?.ctx?.req?.url !== "/confirm"
  ) {
    appContext?.ctx?.res?.writeHead(302, { Location: "/confirm" });
    appContext?.ctx?.res?.end();
    return { ...appProps };
  } else if (
    session &&
    session?.user?.emailVerified &&
    !session?.user?.isOnboarded &&
    appContext?.ctx?.req?.url !== "/onboarding"
  ) {
    appContext?.ctx?.res?.writeHead(302, { Location: "/onboarding" });
    appContext?.ctx?.res?.end();
    return { ...appProps };
  } else if (
    session &&
    session?.user?.emailVerified &&
    session?.user?.isOnboarded &&
    appContext?.ctx?.req?.url === "/onboarding"
  ) {
    appContext?.ctx?.res?.writeHead(302, { Location: "/feed" });
    appContext?.ctx?.res?.end();
    return { ...appProps };
  }

  return { ...appProps, session };
};

export default GlouallyApp;
